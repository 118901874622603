export default class {
  constructor(){
  }
  init(){
  }
  setSwiper(){
    $(".main-slide-holder").each(function(i,el){
      var length = $(el).find(".item").length;
      var loop = false;
      if (length >= 2) {
        loop = true;
      }
      var option = {
        loop:loop,
        watchOverflow:true,
        speed: 800,
        slidesPerView: "auto",
        //spaceBetween: 20,
      }
      var swiper = new Swiper(el,option);
    });
  }
}
