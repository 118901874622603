import WpSwiper from './module/_wpSwiper.webpack.js';
import LocationSearch from './module/_locationSearch.webpack.js';

;(function(){
  ROOT.wpSwiper = new WpSwiper();
  ROOT.locationSearch = new LocationSearch();


  var itemLayout = function(){
    var categoryTags = $(".section-wp-category-top .tag-list-holder");
    if (ROOT.isSp) {
      $(".section-wp-category-top .container-pcsp-size-m").append(categoryTags)
    }
    else{
      $(".section-wp-category-top .info-holder").append(categoryTags)
    }
  }

  //sp pc 切り替え
  if (ROOT.isSp) itemLayout();
  ROOT.ev.on("ON_CHANGE_TARGET",function(n){
    itemLayout();
  });


  //関連アイテム vue
  if ($(".section-relation-items").length) {
    var ecDmain = (document.domain=='www.karrimor.jp') ? 'https://www.karrimor.jp' : 'https://demo.ebisumart.com';
    var ecUrl = (document.domain=='www.karrimor.jp') ? 'https://www.karrimor.jp' : 'https://demo.ebisumart.com/KARRIMOR';
    var ecImage = (document.domain=='www.karrimor.jp') ? 'https://www.karrimor.jp/client_info' : 'https://demo.ebisumart.com/client_info/KARRIMOR';
    ROOT.appRelationIttem = new Vue({
      delimiters: ['[[', ']]'],
      el: '#relation-items',
      data:{
        item_path:ecUrl+'/item/',
        item_image_path:ROOT.productImagePath,
        items:[],
        isSetSwiper:false,
      },
      created:function(){
        var self = this;
        var relationItemsID = $(".section-relation-items").attr("data-relationitem")
        var idArr = relationItemsID.split(',');
        var itemDataArr = [];
        for(let i in idArr) {
          var id = idArr[i].trim();
          var itemData = _.findWhere(ROOT.JSON_DATA.items, {ITEM_CD: id});
          if (itemData) {
            self.items.push(itemData);
          }
        }
      //console.log(self.items)
      },
      mounted:function(){
        var self = this;
        $(function(){
          $("body").imagesLoaded(function(){
            self.isSetSwiper =true;
            setTimeout(function(){
              self.setSlider();
            },100)
            
          })
        });
      },
      methods: {
        setSlider(){
          var self = this;
          ROOT.slider.setWideSlider();
        },
      }
    });
  }
  else{
    ROOT.slider.setWideSlider();
  }

  //ショップ一覧 vue
  if ($(".shoplist-index-holder").length) {
    var shopitems = []
    $(".put-shop-item").each(function(i,el){
      var item = {};
      item.link = $(el).find(".put-shop-item-link").text();
      item.title = $(el).find(".put-shop-item-title").text();
      item.postalCode = $(el).find(".put-shop-item-postalCode").text();
      item.addres = $(el).find(".put-shop-item-adress").text();
      item.tel = $(el).find(".put-shop-item-tel").text();
      item.tags = [];
      $(el).find(".put-shop-item-tag").each(function(index,tag){
        item.tags.push($(tag).text())
      });
      item.lat = $(el).find(".put-shop-item-shop_lat").text();
      item.lon = $(el).find(".put-shop-item-shop_lon").text();
      shopitems.push(item)
    });
  }

  ROOT.appShopList = new Vue({
    delimiters: ['[[', ']]'],
    el: '#shopApp',
    data:{
      geoLocation:false,
      isSubmit:false,
      isSort:false,
      sortItem:null,
      sort:[],
      shopAllItems:shopitems,
      shopItems:[],
      page:null,
      maxPage:null,
    },

    watch:{
      geoLocation(){
        var self = this;
      },
    },
    created:function(){
      var self = this;
    },
    mounted:function(){
      var self = this;
      ROOT.ev.on("GEO_DONE",function(n){
        self.checkItems();

      })
    },
    methods: {
      getLocation(){
        var self = this;
        ROOT.locationSearch.getGeolocation();
      },
      setShopItems(){
        var self = this;
      },
      checkItems(){
        var self = this;
        for(let i in self.shopAllItems) {
          var item = self.shopAllItems[i];
          if (item.lat && item.lon) {
            if (ROOT.locationSearch.locationCheck(item.lat,item.lon)) {
              self.shopItems.push(item);
            }
          }
        }
        self.geoLocation=true;
        self.isSubmit = false;
      }
    }
  });


  //もっと見る
  $(".btn-page-more").on("click",function (argument) {
    var $this = $(this);
    var parents = $this.parent(".more-btn-holder");
    var dataMax = $(this).attr("data-max");
    var dataNextPage = $(this).attr("data-next-page");
    var urlParam = location.search;
    axios({
      method       : 'GET', 
      url          : dataNextPage,
      responseType : 'document', 
    })
    .then((res) => {
      var items = $(res.data).find(".list-layout-pc-4col");
      $(".list-layout-pc-4col").append(items.html())
      var pageUrl = dataNextPage.split("page/")[0]
      var strSplit = dataNextPage.split("page/")[1]
      var number = strSplit.split("/")[0];
      if (urlParam) number = number.split("?")[0];
      if (number==dataMax) {
        $(parents).addClass("hide")
      }
      else{
        var param = "";
        if (urlParam) param = urlParam;
        $this.attr("data-next-page",pageUrl+'page/'+(~~number+1)+param)
      }
    });
    return false;
  })

  if ($(".js-tag-put-holder").length) {
    $(".js-tag-put-holder").each(function(i,el){
      var tagListItem = [];
      var tagEl = $(el).find(".tag-default");
      $(tagEl).each(function(i,tag){
        var tagName = $(tag).attr("data-tagname");
        tagListItem[tagName] = tag;
      })
      //var resultListItem = new Set(tagListItem);

      var tagHolder = $(this).next(".tag-list-holder");
      var tagList = $(tagHolder).find(".tag-list-layout");
      for (var i in tagListItem) {
        var item = tagListItem[i];
        tagList.append(item)
      }
    });
  }


  //アンバサダー並び替え
  if ($(".ambassador-intro-list-holder").length) {
    var sortItems = []
    $(".ambassador-intro-item").each(function(i,el){
      var name = $(el).attr('data-ambassador')
      var date = $(el).attr('data-postdate')
      var contentList = $("#ambassador-"+name);
      var item = {}
      item.content = $(el);
      item.date = date;
      item.name = name;
      item.contentList = contentList;

      sortItems.push(item)
      $(el).remove()
    })
    sortItems.sort(function(a,b) {
      return (a.date < b.date ? 1 : -1);
    });
    for (var i in sortItems) {
      var htmlList = sortItems[i].contentList;
      var html = sortItems[i].content;
      $(".ambassador-intro-list").append(html)
      $(".ambassador-article-list-holder").append(htmlList)
    }
  }
  //マガジン並び替え
  if ($(".magazine-article-list-holder").length) {
    var sortItems = []
    $(".magazine-article-list-holder .put-magazine-list .put-item").each(function(i,el){
      var name = $(el).attr('data-name')
      var date = $(el).attr('data-postdate')
      var contentList = $("#magazine-"+name);
      var item = {}
      item.date = date;
      item.contentList = contentList;
      sortItems.push(item)
    })
    sortItems.sort(function(a,b) {
      return (a.date < b.date ? 1 : -1);
    });
    for (var i in sortItems) {
      var html = sortItems[i].contentList;
      $(".magazine-article-list-holder").append(html)
    }

  }


  //投稿順変更
  ROOT.dropsort = function(){
    var browser = document.sort_form.order.value;
    location.href = location.pathname+document.sort_form.order.value;
  }




})();
