export default class {
  constructor(){
  }
  init(){
  }
  getGeolocation(){
    navigator.geolocation.getCurrentPosition(
      function(position){
        ROOT.currentLocation = {
          lat : position.coords.latitude,
          lon : position.coords.longitude
        }
        //console.log(ROOT.currentLocation)
        ROOT.ev.emit('GEO_DONE', 1);
      },
      function(error) {
        $("body").removeClass("body-disabled");
        ROOT.appShopList.isSubmit = false;
        switch(error.code) {
          case 1: //PERMISSION_DENIED
            alert("位置情報の利用が許可されていません");
          break;
          case 2: //POSITION_UNAVAILABLE
            alert("現在位置が取得できませんでした");
          break;
          case 3: //TIMEOUT
            alert("タイムアウトになりました");
          break;
          default:
            alert("その他のエラー(エラーコード:"+error.code+")");
          break;
        }
      }
    );
  }
  locationCheck(posLat,posLon){
    //現在地の緯度経度
    var lat1 = ROOT.currentLocation.lat;
    var lng1 = ROOT.currentLocation.lon;

    /*shopの座標*/
    var lat2 = Number(posLat);
    var lng2 = Number(posLon);

    //距離の計算//
    function getDistance(lat1, lng1, lat2, lng2) {
      function radians(deg){
        return deg * Math.PI / 180;
      }

      return 6378.14 * Math.acos(Math.cos(radians(lat1))*
      Math.cos(radians(lat2))*
      Math.cos(radians(lng2)-radians(lng1))+
      Math.sin(radians(lat1))*
      Math.sin(radians(lat2)));
    }
    /*結果*/
    if(getDistance(lat1,lng1,lat2,lng2)<=10){
      return true;
    }
    else{
      return false;
    }

  }
}
